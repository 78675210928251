export const convertNodeToText = (richTextField, separator = ' '): string => {
  let result = ''

  for (let i = 0; i < richTextField?.length; i++) {
    const richTextContents = richTextField[i]?.content ?? null

    if (richTextContents) {
      for (let j = 0, k = richTextContents?.length; j < k; j++) {
        const currentRichTextContent = richTextContents[j]

        if ('text' in currentRichTextContent) {
          result += (result ? separator : '') + currentRichTextContent?.text
        }
      }
    }
  }

  return result
}
