import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { NEWSLETTER_TAGS } from '~/lib/constants'

import { asText } from '~/components/Abstracts/Storyblok'
import Newsletter from '~/components/Form/Newsletter'
import Image from '~/components/UI/Image'
import RichText from '~/components/UI/RichText'

import { useStyle } from '~/providers/StyleProvider'

import { NewsletterProps } from '~/data/global-data'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type PushNewsletterProps = NewsletterProps & {
  className?: string
}

const IMAGE_SIZES = [{ ratio: 1 }]

function PushNewsletter({
  className,
  image,
  title,
  subtitle,
  gdprText,
  gdprLabel,
}: PushNewsletterProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.TitleFluid34_56SangBleu,
  })
  const subtitleStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Grotesk,
  })

  return (
    <div className={cx(css.PushNewsletter, className)}>
      {image && (
        <Image
          {...image}
          layout="fill"
          objectFit="cover"
          className={css.image}
          sizesFromBreakpoints={IMAGE_SIZES}
        />
      )}
      <div className={cx(css.grid, gridStyle)}>
        <div className={css.top}>
          {title && (
            <p className={cx(css.title, titleStyle)}>{asText(title)}</p>
          )}
          {subtitle && (
            <p className={cx(css.subtitle, subtitleStyle)}>
              {asText(subtitle)}
            </p>
          )}
        </div>
        <div className={css.body}>
          <Newsletter
            className={css.newsletter}
            fromTag={NEWSLETTER_TAGS.FOOTER}
            theme={GlobalThemeColors.White}
            gdprLabel={gdprLabel}
            gdprText={gdprText}
          />
        </div>
      </div>
    </div>
  )
}

PushNewsletter.defaultProps = {}

export default PushNewsletter
