import { convertNodeToText } from './as-text'

const asText = (structuredText): string => {
  if (
    Object.prototype.toString.call(structuredText?.content) !== '[object Array]'
  ) {
    // console.warn(
    //   `Rich text argument should be an Array. Received ${typeof structuredText}`,
    // )
    return null
  }

  return convertNodeToText(structuredText?.content)
}

export { asText }
